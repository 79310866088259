import { Box, Flex, Grid, Image, Card, CardBody, CardFooter, Text, Button } from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link } from 'react-router-dom';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import Swal from 'sweetalert2';
import Logo from '../Images/logo.png';
import '../Styles/Home.css'
import over from '../Images/start.png'


const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


const stakingContractAddress = '0x6aE30c8b30dE30E6207076264931F543821BB520'; 

const nftContractAddress = '0x6aE30c8b30dE30E6207076264931F543821BB520';


const nftpng = "https://nftstorage.link/ipfs/bafybeibjopkwo5jtobck33rftkj3kfakjk3oi6saampujzk3wchxdrqlmm/";



const UnstakedNFT = () => {

    const { address, isDisconnected } = useAccount();
    const [ownerTokensId, setOwnerTokensId] = useState([]);
    const [nftimguri, settokenUri] = useState([])
    const [TrueXP, settruexp] = useState()
    const [depositValue, setDepositValue] = useState();
    const [trustdepositValue, settrustDepositValue] = useState();
    const [short,setactiveshort] = useState();
    const [newID,settrueids] = useState();

    



    useEffect(() => {
        fetchNftData();
        activeuser();
        
      
        updateData();
    }, [address])

    const updateData = () => {
      if(isDisconnected) {
        setOwnerTokensId([]);
        settokenUri();
        activeuser();
        
      }
    }

    async function activeuser() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
        try {
  
          if(address) {
            const account = address;
            let a = account[38];
            let b = account[39];
            let c = account[40];
            let d = account[41];
            let name = a + b + c + d.toString();


              setactiveshort(name)
              console.log(name) 
          }
        }
        catch (err) {
          console.log(err.message);
        }
      }
  }


    async function fetchNftData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
          try {
    
            if(address) {
              const tokensId = await contract.walletOfOwner(address);
              let ownerTokensId = [];
                tokensId.map((tokenId) => (
                    ownerTokensId.push(parseInt(tokenId._hex))
                ))
                setOwnerTokensId(ownerTokensId)
                console.log(ownerTokensId) 
                setTimeout(fetchNftData, 15000 );
            }
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }

    async function fetchNftXP(token) {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(nftContractAddress, LionNft.abi, provider);
        try {
            const XP = await contract._calculateXP(token);
            let xpearned = await(XP);
            let TrueXP = xpearned.toString();
              console.log(TrueXP) 
              settruexp(TrueXP)
              setTimeout(fetchNftXP, 15000 );
          
        }
        
        catch (err) {
          console.log(err.message);
        }}
      }
  

   
      const handleDepositChange = (e) => {
        setDepositValue(e.target.value)
      }

      const handleTrustDepositChange = (e) => {
        settrustDepositValue(e.target.value)
      }

      

    async function getnftMD(token) {

        try {
        // Interact with contract
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
        const dataOwner = await contract.ownerOf(token);
        const level = await contract.captinLevel(token);

        const totalLvl = parseFloat(level);
        console.log(totalLvl)
        const coldwalletaddress = await contract.coldWallet(address)
        const trustwalletaddress = await contract.trustedWallet(address)
      
        
        await Swal.fire({
          title: `AllBot`,
          
          position: 'top-end',
          
         
          
          showClass: {
            popup: `
              animate__animated
              animate__fadeInLeft
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutLeft
              animate__faster
            `
          },
          grow: 'column',
          width: 380,
          showConfirmButton: false,
          showCloseButton: true,
          
         
          html:
          `
          <center>
       
          <div><b><p class='txtinbar'>Level:</p></div>
          <div><b><p class='coldrsp'>${totalLvl}</b></b></div>
          <br></br>

          <img class='sideimg' height='450' width='250' src=${nftpng + token + ".png"}
          <br></br>

          <div><b><p class='txtinbar'>OWNER:</p></div>
          <div><b><p class='coldrsp'>${dataOwner}</b></b></div>
          <br></br>
          <div><b><p class='txtinbar'>COLD WALLET:</p></div>
          <div><b><p class='coldrsp'>${coldwalletaddress}</b></b></div>
          <br></br>
          <div><b><p class='txtinbar'>TRUSTED WALLET:</p></div>
          <div><b><p class='coldrsp'>${trustwalletaddress}</b></b></div>
          <br></br>
          <br></br>
          
          </center>
          `
          
        })
        setTimeout(getnftMD, 15000 );
      } catch(error) {
        console.log(error)
    }
}
    
            async function BuyRum() {
      
              try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
      
                 let transaction = await contract.BuyRum({value: ethers.utils.parseEther("0.1")});
                  await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
                  Toast.fire({icon: 'success',title: 'Successful App Will Update.'})
                 
                 
                }
                catch (err) {
                  console.log(err.message);
                  Toast.fire({icon: 'error',title: (err.message)})
                }
              }

              async function UseRum(tokenid) {
      
                try {
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const signer = provider.getSigner();
                  const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
        
                   let transaction = await contract.useRum(tokenid);
                    await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
                    Toast.fire({icon: 'success',title: 'Successful App Will Update.'})
                   
                  }
                  catch (err) {
                    console.log(err.message);
                    Toast.fire({icon: 'error',title: (err.message)})
                  }
                }

                async function UseChest(tokenid) {
      
                  try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
          
                     let transaction = await contract.useWarchest(tokenid);
                      await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
                      Toast.fire({icon: 'success',title: 'Successful App Will Update.'})
                     
                     
                    }
                    catch (err) {
                      console.log(err.message);
                      Toast.fire({icon: 'error',title: (err.message)})
                    }
                  }


              async function BuyChest() {
      
                try {
                  const provider = new ethers.providers.Web3Provider(window.ethereum);
                  const signer = provider.getSigner();
                  const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
        
                   let transaction = await contract.BuyWarchest({value: ethers.utils.parseEther("0.5")});
                    await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
                    Toast.fire({icon: 'success',title: 'Successful App Will Update.'})
                   
                   
                  }
                  catch (err) {
                    console.log(err.message);
                    Toast.fire({icon: 'error',title: (err.message)})
                  }
                }


                async function ClaimXP(token) {
      
                  try {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
          
                     let transaction = await contract.claimXpSetLevel(token);
                      await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
                      Toast.fire({icon: 'success',title: 'Successful App Will Update.'})
                     
                     
                    }
                    catch (err) {
                      console.log(err.message);
                      Toast.fire({icon: 'error',title: (err.message)})
                    }
                  }




      async function ColdWallet(depositValue) {
      
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
          const newContract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
          
            let transaction = await contract.setColdWallet(depositValue);
            await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
            Toast.fire({icon: 'success',title: 'Successful'})
           
          }
        
          catch (err) {
            console.log(err.message);
            Toast.fire({icon: 'error',title: (err.message)})
          }
        
      }


      async function TrustWallet(trustdepositValue) {
      
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, signer);
          const newContract = new ethers.Contract(nftContractAddress, LionNft.abi, signer);
          
            let transaction = await contract.setTrustedWallet(trustdepositValue);
            await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
            Toast.fire({icon: 'success',title: 'Successful.'})
          
           
          }
        
          catch (err) {
            console.log(err.message);
            Toast.fire({icon: 'error',title: (err.message)})
          }
        
      }





    return (
        <Box border="2px" borderColor="#9F7AEA" borderRadius="30px"  px="20px" pb="25px">
            <Flex justifyContent="space-between" alignItems="center" wrap="wrap" gap="25px" my="25px">
                <Text className='cardtxt' fontSize="30px"  fontWeight="bold" >ALLBOTS INTERACTIVE TERMINAL</Text>
                <Text className='cardtxt' fontSize="30px"  fontWeight="bold" >USER: {short}</Text>
                {!isDisconnected}
            </Flex>
            <br></br>
            <br></br>

            <Grid templateColumns={{base: 'repeat(1, fr)', md: 'repeat(2, 1fr)'}} gap="50px">
              
            
            {ownerTokensId.length != 0 ? (
            ownerTokensId.map((token, index) => (
                
            <Card direction={{ base: 'column', sm: 'row' }}  backgroundColor="#0a0a0a" key={index} >
              

                    <CardBody className="cardbody" p="0" overflow="hidden" borderTopRadius="80px">


                    <Popup className='popupimage'
                    trigger={   <div className='nft-container'>
                    <img
                      className='nft-image'
                      src={`${nftpng}${token}.png`}
                      alt='nft-image'
                    />
                    <div className='nft-overlay'>
                      <img src={`${over}`} alt='overlay' />
                    </div>
                  </div>
                      
                      
                    
                    }
                        
    
    modal
    nested
  >
    {close => (
      <div className="modal">
        <div className='headerMD'>
          <h1>Earned XP: {TrueXP} <br></br><button className='btntxt'  variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => fetchNftXP([token])}>CHECK </button></h1>
        </div>
                        <br></br>
        
        
        <button className="close" onClick={close}>
          &times;
        </button>
        
       
        <div className="actions">

       <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => ClaimXP([token])}>
            <font><b>Claim XP</b></font>
                        </Button>
                        <br></br>
                        <br></br>
                        <Button className='btnsubmit css-1xyobum' border='2px'  variant='transparent' fontSize="10px" colorScheme='blue' color='#E9D8FD' onClick={() => getnftMD([token])}>
                         <font><b>Stats</b></font>
                        </Button>
                        <br></br>
                        <br></br>
          

                        <label className='btnlabel' for="Cwallet">Cold Wallet:</label>
                        <br></br>
                        
                        <input type="text" className="form-control" onChange={handleDepositChange} value={depositValue} placeholder="Enter Wallet"></input> 
                        <br></br>
                        <br></br>
                        <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => ColdWallet(depositValue)}>
                        <font><b>Set Cold Wallet</b></font>
                        </Button>
                        <br></br>
                        <br></br>
                        




                        <label className='btnlabel' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' for="Cwallet">Trusted Wallet:</label>
                        <br></br>
                        <input type="text" className="form-control" onChange={handleTrustDepositChange} value={trustdepositValue} placeholder="Enter Wallet"></input> 
                        <br></br>
                        <br></br>
                        <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => TrustWallet(trustdepositValue)}>
                        <font><b>Set Trusted Wallet</b></font>
                        </Button>
                        <br></br>
                        <br></br>


                       



                       <Button className=' ' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD'onClick={() => BuyRum([token])}>
                        <font><b>Buy RAM</b></font>
                        </Button>
    
                        <br></br>
                        <br></br>
                        <Button className='btnsubmit css-1xyobum' border='2px' variant='transparent' fontSize="10px" colorScheme='blue' color='#E9D8FD' onClick={() => UseRum([token])}>
                        <font><b>Consume RAM</b></font>
                        </Button>
    
                        <br></br>
                        <br></br>

                        <Button className='btnsubmit css-1xyobum' border='2px' variant='transparent' fontSize="10px" colorScheme='blue' color='#E9D8FD' onClick={() => BuyChest([token])}>
                        <font><b>Buy Allinium</b></font>
                        </Button>
                        <br></br>
                        <br></br>
                        <Button className='btnsubmit css-1xyobum' border='2px' variant='transparent' fontSize="10px" colorScheme='blue' color='#E9D8FD' onClick={() => UseChest([token])}>
                        <font><b>Consume Allinium</b></font>
                        </Button>
    
                        

                       
                        <br></br>
                        <br></br>
                        <br></br>
                       
         </div>
       </div>
     )}
    </Popup>

               
                    </CardBody>
                    
                    
   

  
</Card>

 ))):( <Box className='nftMint' >ACCESS DENIED NFT NOT FOUND.
 
 </Box>)}

      </Grid>
    </Box>
  );
};

export default UnstakedNFT;