import React, {useState, useEffect} from 'react';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import Logo from '../Images/logo.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import '../Styles/Home.css'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})




const nftimg = 'https://nftstorage.link/ipfs/bafybeibjopkwo5jtobck33rftkj3kfakjk3oi6saampujzk3wchxdrqlmm/';
const stakingContractAddress = '0x6aE30c8b30dE30E6207076264931F543821BB520';
const nftlink = 'https://opensea.io/assets/ethereum/0x6aE30c8b30dE30E6207076264931F543821BB520/'

const Leaderboard = () => {

    const { address, isConnecting, isDisconnected} = useAccount();

    const [firstid, setfirstid] = useState([]);
    const [firstowner, setfirstowner] = useState([]);
    const [firstlevel, setfirstlevel] = useState([]);
    const [firstimg, setfirstimg] = useState([]);
    const [firstlink, setfirstlink] = useState([]);

    const [secondid, setsecondid] = useState([]);
    const [secondowner, setsecondowner] = useState([]);
    const [secondlevel, setsecondlevel] = useState([]);
    const [secondimg, setsecondimg] = useState([]);
    const [secondlink, setsecondlink] = useState([]);

    const [thirdid, setthirdid] = useState([]);
    const [thirdowner, setthirdowner] = useState([]);
    const [thirdlevel, setthirdlevel] = useState([]);
    const [thirdimg, setthirdimg] = useState([]);
    const [thirdlink, setthirdlink] = useState([]);

    const [fourthid, setfourthid] = useState([]);
    const [fourthowner, setfourthowner] = useState([]);
    const [fourthlevel, setfourthlevel] = useState([]);
    const [fourthimg, setfourthimg] = useState([]);
    const [fourthlink, setfourthlink] = useState([]);

    const [fifthid, setfifthid] = useState([]);
    const [fifthowner, setfifthowner] = useState([]);
    const [fifthlevel, setfifthlevel] = useState([]);
    const [fifthimg, setfifthimg] = useState([]);
    const [fifthlink, setfifthlink] = useState([]);

    const [sixthid, setsixthid] = useState([]);
    const [sixthowner, setsixthowner] = useState([]);
    const [sixthlevel, setsixthlevel] = useState([]);
    const [sixthimg, setsixthimg] = useState([]);
    const [sixthlink, setsixthlink] = useState([]);

    const [seventhid, setseventhid] = useState([]);
    const [seventhowner, setseventhowner] = useState([]);
    const [seventhlevel, setseventhlevel] = useState([]);
    const [seventhimg, setseventhimg] = useState([]);
    const [seventhlink, setseventhlink] = useState([]);

    const [eigthid, seteigthid] = useState([]);
    const [eigthowner, seteigthowner] = useState([]);
    const [eigthlevel, seteigthlevel] = useState([]);
    const [eigthimg, seteigthimg] = useState([]);
    const [eigthlink, seteigthlink] = useState([]);

    const [ninthid, setninthid] = useState([]);
    const [ninthowner, setninthowner] = useState([]);
    const [ninthlevel, setninthlevel] = useState([]);
    const [ninthimg, setninthimg] = useState([]);
    const [ninthlink, setninthlink] = useState([]);

    const [tenthid, settenthid] = useState([]);
    const [tenthowner, settenthowner] = useState([]);
    const [tenthlevel, settenthlevel] = useState([]);
    const [tenthimg, settenthimg] = useState([]);
    const [tenthlink, settenthlink] = useState([]);



    

    
   

    useEffect(() => {
  
        fetchfirsttokenidData();
       
        fetchsecondtokenidData();
        
        fetchthirdtokenidData();

        fetchfourthtokenidData();

        fetchfifthtokenidData();

        fetchsixthtokenidData();
        
        fetchseventhtokenidData();

        fetcheigthtokenidData();

        fetchninthtokenidData();

        fetchtenthtokenidData();

       
       


      updateData();

    }, [address])

    const updateData = () => {
      if(isDisconnected) {
     
      }
    }
   
    async function fetchfirsttokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const first = await contract.getLeaderboard();
              let firstid = first[0][0].toString();
              let firstowner = first[0][2].toString();
              let firstlevel = first [0][1].toString();
              let firstimg = nftimg + firstid + ".png";
              let firstlink = nftlink + firstid;
              console.log(firstid)
              console.log(firstowner)
              console.log(firstlevel)
              console.log(firstimg)
              setfirstid(firstid)
              setfirstowner(firstowner)
              setfirstlevel(firstlevel)
              setfirstimg(firstimg)
              setfirstlink(firstlink)

              setTimeout(fetchfirsttokenidData, 15000 );
          }
          
          catch (err) {
            console.log(err.message);
          }
        }
    }



    async function fetchsecondtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const second = await contract.getLeaderboard();
              let secondid = second[1][0].toString();
              let secondowner = second[1][2].toString();
              let secondlevel = second [1][1].toString();
              let secondimg = nftimg + secondid + ".png";
              let secondlink = nftlink + secondid;
              console.log(secondid)
              console.log(secondowner)
              console.log(secondlevel)
              console.log(secondimg)
              setsecondid(secondid)
              setsecondowner(secondowner)
              setsecondlevel(secondlevel)
              setsecondimg(secondimg)
              setsecondlink(secondlink)
              setTimeout(fetchsecondtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }



    async function fetchthirdtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const third = await contract.getLeaderboard();
              let thirdid = third[2][0].toString();
              let thirdowner = third[2][2].toString();
              let thirdlevel = third [2][1].toString();
              let thirdimg = nftimg + thirdid + ".png";
              let thirdlink = nftlink + thirdid;
              console.log(thirdid)
              console.log(thirdowner)
              console.log(thirdlevel)
              console.log(thirdimg)
              setthirdid(thirdid)
              setthirdowner(thirdowner)
              setthirdlevel(thirdlevel)
              setthirdimg(thirdimg)
              setthirdlink(thirdlink)
              setTimeout(fetchthirdtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }



    async function fetchfourthtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const fourth = await contract.getLeaderboard();
              let fourthid = fourth[3][0].toString();
              let fourthowner = fourth[3][2].toString();
              let fourthlevel = fourth [3][1].toString();
              let fourthimg = nftimg + fourthid + ".png";
              let fourthlink = nftlink + fourthid;
              console.log(fourthid)
              console.log(fourthowner)
              console.log(fourthlevel)
              console.log(fourthimg)
              setfourthid(fourthid)
              setfourthowner(fourthowner)
              setfourthlevel(fourthlevel)
              setfourthimg(fourthimg)
              setfourthlink(fourthlink)
              setTimeout(fetchfourthtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function fetchfifthtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const fifth = await contract.getLeaderboard();
              let fifthid = fifth[4][0].toString();
              let fifthowner = fifth[4][2].toString();
              let fifthlevel = fifth [4][1].toString();
              let fifthimg = nftimg + fifthid + ".png";
              let fifthlink = nftlink + fifthid;
              console.log(fifthid)
              console.log(fifthowner)
              console.log(fifthlevel)
              console.log(fifthimg)
              setfifthid(fifthid)
              setfifthowner(fifthowner)
              setfifthlevel(fifthlevel)
              setfifthimg(fifthimg)
              setfifthlink(fifthlink)
              setTimeout(fetchfifthtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }



    async function fetchsixthtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const sixth = await contract.getLeaderboard();
              let sixthid = sixth[5][0].toString();
              let sixthowner = sixth[5][2].toString();
              let sixthlevel = sixth [5][1].toString();
              let sixthimg = nftimg + sixthid + ".png";
              let sixthlink = nftlink + sixthid;
              console.log(sixthid)
              console.log(sixthowner)
              console.log(sixthlevel)
              console.log(sixthimg)
              setsixthid(sixthid)
              setsixthowner(sixthowner)
              setsixthlevel(sixthlevel)
              setsixthimg(sixthimg)
              setsixthlink(sixthlink)
              setTimeout(fetchsixthtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function fetchseventhtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const seventh = await contract.getLeaderboard();
              let seventhid = seventh[6][0].toString();
              let seventhowner = seventh[6][2].toString();
              let seventhlevel = seventh [6][1].toString();
              let seventhimg = nftimg + seventhid + ".png";
              let seventhlink = nftlink + seventhid;
              console.log(seventhid)
              console.log(seventhowner)
              console.log(seventhlevel)
              console.log(seventhimg)
              setseventhid(seventhid)
              setseventhowner(seventhowner)
              setseventhlevel(seventhlevel)
              setseventhimg(seventhimg)
              setseventhlink(seventhlink)
              setTimeout(fetchseventhtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }



    async function fetcheigthtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const eigthth = await contract.getLeaderboard();
              let eigththid = eigthth[7][0].toString();
              let eigththowner = eigthth[7][2].toString();
              let eigththlevel = eigthth [7][1].toString();
              let eigththimg = nftimg + eigththid + ".png";
              let eigthlink = nftlink + eigththid;
              console.log(eigththid)
              console.log(eigththowner)
              console.log(eigththlevel)
              console.log(eigththimg)
              seteigthid(eigththid)
              seteigthowner(eigththowner)
              seteigthlevel(eigththlevel)
              seteigthimg(eigththimg)
              seteigthlink(eigthlink)
              setTimeout(fetcheigthtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function fetchninthtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const ninth = await contract.getLeaderboard();
              let ninthid = ninth[8][0].toString();
              let ninthowner = ninth[8][2].toString();
              let ninthlevel = ninth [8][1].toString();
              let ninthimg = nftimg + ninthid + ".png";
              let ninthlink = nftimg + ninthid;
              console.log(ninthid)
              console.log(ninthowner)
              console.log(ninthlevel)
              console.log(ninthimg)
              setninthid(ninthid)
              setninthowner(ninthowner)
              setninthlevel(ninthlevel)
              setninthimg(ninthimg)
              setninthlink(ninthlink)
              setTimeout(fetchninthtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function fetchtenthtokenidData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
          try {
             
              const tenth = await contract.getLeaderboard();
              let tenthid = tenth[9][0].toString();
              let tenthowner = tenth[9][2].toString();
              let tenthlevel = tenth [9][1].toString();
              let tenthimg = nftimg + tenthid + ".png";
              let tenthlink = nftlink + tenthid;
              console.log(tenthid)
              console.log(tenthowner)
              console.log(tenthlevel)
              console.log(tenthimg)
              settenthid(tenthid)
              settenthowner(tenthowner)
              settenthlevel(tenthlevel)
              settenthimg(tenthimg)
              settenthlink(tenthlink)
              setTimeout(fetchtenthtokenidData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }



   


   

    return (
        <Box mb="100px">
            <Box >
              <center>
                <Image
                    src={Logo}
                    alt='MemeAI'
                    transform="scale(1)"
                    width='150px'
                    transition= "0.3s ease-in-out"
                    _hover={{transform: "scale(1.15)"}}
                    />
                    <Heading className='headertxt'  bgGradient='linear(to-r, #4DFFFF, #4D82FF)' bgClip='text' size={{base: 'md', md: "xl"}}>
                     LEADERBOARD
                    </Heading>
                </center>
                <br></br>

                


                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="30px">
                   

                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text>
                            How It Works?
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                                The Leaderboard is based on the levels of the ALLBOTS. Purchase RAM or ALLINIUM to boost your position today!
                            </Text>
                        </CardBody>
                    </Card>


                   

                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text>
                        When does it update?
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text className='leadertxt'  color="whiteAlpha.800">
                            The Leaderboard is automatically updated every 15 seconds by calling on the stats on chain. 
                            </Text>
                        </CardBody>
                    </Card>
                    <br>
                </br> <br>
                </br>


                </Grid>
                






                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 5fr)'}} gap="30px">
                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            1st Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="15px">
                               Token ID: {firstid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="15px">
                            Token Level: {firstlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${firstimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="15px">
                                Owner: {firstowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(firstlink, '_blank')}>
                        <font><b>OpenSea #{firstid}</b></font>
                        </Button>
                            
                        </CardBody>
                    </Card>


                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            2st Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {secondid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {secondlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${secondimg}`}
                            width="180px"
                            height="180px" />
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {secondowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(secondlink, '_blank')}>
                        <font><b>OpenSea #{secondid}</b></font>
                        </Button>
                            
                        </CardBody>
                    </Card>



                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            3rd Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {thirdid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {thirdlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${thirdimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {thirdowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(thirdlink, '_blank')}>
                        <font><b>OpenSea #{thirdid}</b></font>
                        </Button>
                            
                        </CardBody>
                    </Card>



                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            4th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {fourthid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {fourthlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${fourthimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {fourthowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(fourthlink, '_blank')}>
                        <font><b>OpenSea #{fourthid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>



                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            5th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {fifthid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {fifthlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${fifthimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {fifthowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(fifthlink, '_blank')}>
                        <font><b>OpenSea #{fifthid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>


                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            6th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {sixthid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {sixthlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${sixthimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {sixthowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(sixthlink, '_blank')}>
                        <font><b>OpenSea #{sixthid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>




                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            7th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {seventhid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {seventhlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${seventhimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {seventhowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(seventhlink, '_blank')}>
                        <font><b>OpenSea #{seventhid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>


                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            8th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {eigthid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {eigthlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${eigthimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {eigthowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(eigthlink, '_blank')}>
                        <font><b>OpenSea #{eigthid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>





                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            9th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {ninthid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {ninthlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${ninthimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {ninthowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(ninthlink, '_blank')}>
                        <font><b>OpenSea #{ninthid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>

                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt' fontSize="30px">
                        <Text>
                            10th Place
                        </Text>
                        </CardHeader>

                        <CardBody  textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                            
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token ID: {tenthid}
                            </Text>
                            <Text color="whiteAlpha.800" fontSize="20px">
                            Token Level: {tenthlevel}
                            </Text>
                            <img
                            className='nft-image-lead'
                            src={`${tenthimg}`}
                            width="180px"
                            height="180px"/>
                            <Text color="whiteAlpha.800" fontSize="20px">
                                Owner: {tenthowner}
                            </Text>
                            <Button className='btnsubmit' border='2px' variant='transparent' fontSize="20px" colorScheme='blue' color='#E9D8FD' onClick={() => window.open(tenthlink, '_blank')}>
                        <font><b>OpenSea #{tenthid}</b></font>
                        </Button>
                           
                        </CardBody>
                    </Card>


                </Grid>
                
            </Box>
            

           
        </Box>
    );
};

export default Leaderboard;