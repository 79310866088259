import { Flex, Heading, Image, Button, Text, Menu,
  MenuButton,
  MenuList, IconButton, Box } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import React, { useEffect } from 'react';
import Logo from '../Images/logo.png';
import { Link } from 'react-router-dom';

import { ConnectButton } from '@rainbow-me/rainbowkit';

const Navbar = ({ toggleAudio }) =>  {

    return (
        <Flex justify="space-between" align="center" mb="30px" py="25px" position="contain" top="0" zIndex="contain" >
       

        <Flex gap="20px" align="center" wrap="wrap" display={{base: 'none', lg: 'flex'}}>

  

              
        </Flex>

      <Box display={{base: 'none', lg: 'block'}}> 
              <ConnectButton />
               {/* Add an audio control button */}
     
      </Box>

        <Box display={{base: 'block', lg: 'none'}}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FaBars />}
              colorScheme="black"
              font-family='hacked-kerX'
              
            />
            <MenuList bg="transparent" >

                <ConnectButton />
                 {/* Add an audio control button */}
    
               

                

                
            </MenuList>
          </Menu>
        </Box>
    </Flex>
    );
};

export default Navbar;