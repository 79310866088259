import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  Box,
  extendTheme
} from '@chakra-ui/react';
import { BrowserRouter, Routes,  Route } from 'react-router-dom';
import Staking from 'Pages/Staking';
import 'Styles/Home.css';
import Navbar from 'Components/Navbar';
import Loader from './Loader';
import Mint from 'Pages/Mint';
import Faq from 'Pages/Faq';
import Leaderboard from 'Pages/Leaderboard';
import Dao from 'Pages/Dao';






import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  midnightTheme,
} from '@rainbow-me/rainbowkit';
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { red } from '@mui/material/colors';

// Add more wallet connexion with rainbowkit

const { chains, provider } = configureChains(
  [chain.mainnet],
  [
    // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})
// Add more wallet connexion with rainbowkit

// Custom Chakra default theme 

const colors = {
  primary: {
    900: '#050405',
    800: '#3A72F3',
    700: '#97C0FA',
    600: '#F9B208',
    500: '#FB5BB9',
    400: '#EB2A64',
    300: '#16A8FB'
  },
  secondary: {
    900: '#01040D',
    800: '#575761',
    700: '#1C1326'
  }
  
}

const styles = {
  global: {
    'html, body, css': {
      
      color: 'secondary.800',
      lineHeight: 'tall',
      fontSize: '16px',
      overflow: 'scroll',
      Font: 'hacked-kerX',
      backgroundImage: `url("https://i.ibb.co/xSKdZk8/Untitled.png")`, 
  
      boxSizing: 'border-box'
    },
    a: {
      fontSize: "20px",
      textDecoration: "none"
    } 
  },
}


const breakpoints = {
  sm: '385px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1536px',
}

const theme = extendTheme({ styles, breakpoints})


// Custom Chakra default theme 

function App() {
  const [loader, setLoader] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(true); // Initially set to true to start playing
  


  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 4000)
  }, [])
  const toggleAudio = () => {
    // Toggle the audio playback state
    setAudioPlaying(!audioPlaying);
  };


  return loader ? (
    <Loader/>
  ) : (
        <ChakraProvider theme={theme} >
            <WagmiConfig client={wagmiClient}>
              <RainbowKitProvider chains={chains} theme={midnightTheme({
                accentColor: 'rgba(97, 97, 97, 0.47)',
                accentColorForeground: 'rgba(125, 133, 140, 0.8)',
                borderRadius: 'medium',
                color:  '#13ffd8',
                Body: 'hacked-kerX',
                

              })}>
                <BrowserRouter>
                <Box px={{base: "5%", md:"7%", lg: "10%"}}>
                <Navbar toggleAudio={toggleAudio} />
                  <Routes>
                    <Route path="/" element={<Mint />}/>
                  </Routes>
          
   
                </Box>
                </BrowserRouter>
              </RainbowKitProvider>
            </WagmiConfig>
        </ChakraProvider>
  );
}



export default App;
