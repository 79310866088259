import React, {useState, useEffect} from 'react';


import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import LionNft from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import LionDao from '../artifacts/contracts/LionDao.sol/LionDao.json';
import Logo from '../Images/logo.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';

import '../Styles/Home.css'





import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})





const LionTokenContractAddress = '0x9b2b931d6ab97b6a887b2c5d8529537e6fe73ebe';
const LionNFTContractAddress = '0x6aE30c8b30dE30E6207076264931F543821BB520';
const LionDaoContractAddress = '0xf10206a4651a99BE34611b7A6Dfe02b9f9308b8e';

const Dao = () => {

    const { address, isConnecting, isDisconnected} = useAccount();
   
   
    const [shillPoints, setShillPoints] = useState()
    const [alphaPoints, setAlphaPoints] = useState()
    const [bravoPoints, setBravoPoints] = useState()


    const [tokenPoints, settokenPoints] = useState()
   
    const [callsign, setcallsign] = useState()

    const [proposal1, setProposal1] = useState()
    const [proposal2, setProposal2] = useState()
    const [proposal3, setProposal3] = useState()

    const [message1, setMessage1] = useState()
    const [message2, setMessage2] = useState()
    const [message3, setMessage3] = useState()

    const [start1, setStart1] = useState()
    const [start2, setStart2] = useState()
    const [start3, setStart3] = useState()

    const [end1, setEnd1] = useState()
    const [end2, setEnd2] = useState()
    const [end3, setEnd3] = useState()

    const [votes1, setvotes1] = useState()
    const [votes2, setvotes2] = useState()
    const [votes3, setvotes3] = useState()

    const [result1, setresult1] = useState()
    const [result2, setresult2] = useState()
    const [result3, setresult3] = useState()

    const [balance, setBalance] = useState()
    const [nftbalance, setnftBalance] = useState()



    

    



    





    const [depositValuepusd4, setDepositValuepusd4] = useState();
    const [depositValuepusd3, setDepositValuepusd3] = useState();
    const [depositValuepusd2, setDepositValuepusd2] = useState();
    const [depositValuepusd1, setDepositValuepusd1] = useState();
   
   

   



   


    const handleDepositChangepusd2 = (e) => {
      setDepositValuepusd2(e.target.value)
    }
   

    const handleDepositChangepusd1 = (e) => {
      setDepositValuepusd1(e.target.value)
    }

    const handleDepositChangepusd3 = (e) => {
      setDepositValuepusd3(e.target.value)
    }

    

    
 


    useEffect(() => {
  
     
      updateData();

     
      
     
      fetchcallSign();

      fetchvote1();
      fetchvote2();
      fetchvote3();
      fetchbalance();
      fetchnftbalance();
      fetchresult1();
      fetchresult2();
      fetchresult3();


      fetchtotalvotes1();
      fetchtotalvotes2();
      fetchtotalvotes3();
      
     


      updateData();
    }, [address])

    const updateData = () => {
      if(isDisconnected) {
      
      
      }
    }


    async function fetchresult1() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.vote1(0);
            let response = await(call);
            let denied = response[3].toString()
            let approval = response[2].toString()
            let a = response[6].toString();
            if(a === "0"){
              let a = "Not In Vote"
              setresult1(a)
            }
            else if (a === "1"){
              let b = "Voting In Session"
              setresult1(b)
            }
            else if (a === "2"){
              let c = "Passed For:" + " " + approval + " " + "Against:" + " " + denied;
              setresult1(c)
            }
            else if (a === "3"){
              let d = "Failed For:" + " " + approval + " " + "Against:" + " " + denied;
              setresult1(d)
            }
           

          }setTimeout(fetchresult1, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    

    async function fetchresult2() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.vote2(0);
            let response = await(call);
            let denied = response[3].toString()
            let approval = response[2].toString()
            let a = response[6].toString();
            if(a === "0"){
              let a = "Not In Vote"
              setresult2(a)
            }
            else if (a === "1"){
              let b = "Voting In Session"
              setresult2(b)
            }
            else if (a === "2"){
              let c = "Passed For:" + " " + approval + " " + "Against:" + " " + denied;
              setresult2(c)
            }
            else if (a === "3"){
              let d = "Failed For:" + " " + approval + " " + "Against:" + " " + denied;
              setresult2(d)
            }
           

          }setTimeout(fetchresult2, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    

    async function fetchresult3() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.vote3(0);
            let response = await(call);
            let denied = response[3].toString()
            let approval = response[2].toString()
            let a = response[6].toString();
            if(a === "0"){
              let a = "Not In Vote"
              setresult3(a)
            }
            else if (a === "1"){
              let b = "Voting In Session"
              setresult3(b)
            }
            else if (a === "2"){
              let c = "Passed For:" + " " + approval + " " + "Against:" + " " + denied;
              setresult3(c)
            }
            else if (a === "3"){
              let d = "Failed For:" + " " + approval + " " + "Against:" + " " + denied;
              setresult3(d)
            }
           

          }setTimeout(fetchresult3, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    


    async function fetchnftbalance() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionNFTContractAddress, LionNft.abi, provider);
        try {
          if(address) {
            const call = await contract.balanceOf(address);
            let response = await(call);
            let num = response ;
            let nums = num
            let total =  "ALL-BOT NFT's Owned:" + " " + nums;
           
            let proposal = total.toString();

                setnftBalance(proposal)
                console.log(proposal)
               
            }
                setTimeout(fetchnftbalance, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}   

    async function fetchbalance() {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionTokenContractAddress, LionToken.abi, provider);
        const contractDao = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          const user = await contractDao.users(address);
          
    
          if (user[0] === "0x0000000000000000000000000000000000000000") {
            let signs = "";
            setBalance(signs);
            console.log(signs);
          } else {
            const response = await contract.balanceOf(address);
            let num = response / 1000000000;
            let nums = num.toFixed(2);
            let total = "ALLIN Balance: " + nums;
            let proposal = total.toString();
    
            setBalance(proposal);
            console.log(proposal);
          }
    
          setTimeout(fetchbalance, 15000);
        } catch (err) {
          console.log(err.message);
        }
      }
    }
    




    async function fetchtotalvotes1() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            
            const call = await contract.totalVotes1();
            let response = await(call);
           
            let proposal = response.toString();

                setvotes1(proposal)
                console.log(proposal)
               
            }
                setTimeout(fetchtotalvotes1, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    



    

    async function fetchtotalvotes2() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.totalVotes2();
            let response = await(call);
           
            let proposal = response.toString();

            setvotes2(proposal)
                console.log(proposal)
               
            }
                setTimeout(fetchtotalvotes2, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    

    async function fetchtotalvotes3() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.totalVotes3();
            let response = await(call);
           
            let proposal = response.toString();

            setvotes3(proposal)
                console.log(proposal)
               
            }
                setTimeout(fetchtotalvotes3, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    
            

   

   







    async function initalizeUser() {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
       
        
        
          let transaction = await contract.createUser();
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
          
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }


    async function setExc1Tweet(depositValuepusd2) {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
        let name = depositValuepusd2.toString()
        
        
          let transaction = await contract.approvePool1(name);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }

    async function setDeny1(depositValuepusd2) {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
        let name = depositValuepusd2.toString()
        
        
          let transaction = await contract.denyPool1(name);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }




    async function setExc2Tweet(depositValuepusd2) {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
        let name = depositValuepusd2.toString()
        
        
          let transaction = await contract.approvePool2(name);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }

    async function setDeny2(depositValuepusd2) {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
        let name = depositValuepusd2.toString()
        
        
          let transaction = await contract.denyPool2(name);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }




    async function setExc3Tweet(depositValuepusd3) {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
        let name = depositValuepusd3.toString()
        
        
          let transaction = await contract.approvePool3(name);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }

    async function setDeny3(depositValuepusd3) {
      
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, signer);
        let name = depositValuepusd3.toString()
        
        
          let transaction = await contract.denyPool3(name);
          await transaction.wait(Toast.fire({icon: 'info',title: 'Pending Please Wait.'}));
          Toast.fire({icon: 'success',title: 'Successful App Updating'})
         
        }
      
        catch (err) {
          console.log(err.message);
          Toast.fire({icon: 'error',title: (err.message)})
        }
      
    }


   

   











  




















   

    

   

    async function fetchvote1() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.vote1(0);
            let response = await(call);
           
          
            let proposal = response[0].toString();
            
            let message = response[1].toString();
           
            let starttime = response[4].toString();

            let endtime = response[5].toString();
            
            

          
                setProposal1(proposal)
                console.log(proposal)

                setMessage1(message)
                console.log(message)
                
                setStart1(starttime)
                console.log(starttime) 

                setEnd1(endtime)
                console.log(endtime)}

                setTimeout(fetchvote1, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    
            



    async function fetchvote2() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.vote2(0);
            let response = await(call);
           
          
            let proposal = response[0].toString();
            
            let message = response[1].toString();
           
            let starttime = response[4].toString();

            let endtime = response[5].toString();
            
            

          
                setProposal2(proposal)
                console.log(proposal)
                setMessage2(message)
                console.log(message)
                
                setStart2(starttime)
                console.log(starttime) 

                setEnd2(endtime)
                console.log(endtime)}

                setTimeout(fetchvote2, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    
            






    async function fetchvote3() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.vote3(0);
            let response = await(call);
           
          
            let proposal = response[0].toString();
            
            let message = response[1].toString();
           
            let starttime = response[4].toString();

            let endtime = response[5].toString();
            
            

          
                setProposal3(proposal)
                console.log(proposal)
                setMessage3(message)
                console.log(message)
                
                setStart3(starttime)
                console.log(starttime) 

                setEnd3(endtime)
                console.log(endtime)}

                setTimeout(fetchvote3, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}    
            















           


    async function fetchcallSign() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(LionDaoContractAddress, LionDao.abi, provider);
        try {
          if(address) {
            const call = await contract.users(address);
            let response = await(call);

            if (response[0] === "0x0000000000000000000000000000000000000000"){
              
            let signs = "Please Initialize Wallet";
            setcallsign(signs)
            console.log(signs)
            }
           else{
          
            let sign = response[0];
            let a = sign[38];
            let b = sign[39];
            let c = sign[40];
            let d = sign[41];
            let name = a + b + c + d.toString();
            let signs = "Address:" + " " + name;
            setcallsign(signs)
            console.log(signs)
           }
            let TokenPoints = response[1].toString();
            let TokenPointss = "AllIn Balance:" + " " + TokenPoints;
            let BravoPoints = response[3].toString();
            let BravoPointss = "Proposal 1 Votes:" + " " + BravoPoints;
            let AlphaPoints = response[4].toString();
            let AlphaPointss = "Proposal 2 Votes:" + " " + AlphaPoints;
            let shillPoints = response[5].toString();
            let ShillPointss = "Proposal 3 Votes:" + " " + shillPoints;
            

          
              
                settokenPoints(TokenPointss)
                console.log(TokenPointss)
                setBravoPoints(BravoPointss)
                console.log(BravoPointss)
                setAlphaPoints(AlphaPointss)
                console.log(AlphaPointss)
                setShillPoints(ShillPointss)
                console.log(ShillPointss) }
                setTimeout(fetchcallSign, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
    }}







    return (
        <Box mb="100px">
            <Box >
              <center>
                <Image 
                    src={Logo}
                    alt=''
                    transform="scale(1)"
                    width='200px'
                    transition= "0.3s ease-in-out"
                    _hover={{transform: "scale(1.15)"}}
                    />
                    
                    <br></br>
                    <br></br>
                    
                    
                   
                  
              
                </center>
                <br></br>

                


                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)'}} gap="60px">
                   







                    
                  
                    <Card  className='DaoHeader' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="20px" fontWeight="bold">
                        <CardHeader className='cardtxtdao'>
                        <Text className='heading'><b>
                            YOUR VOTING RIGHTS
                            </b></Text>
                   

                        </CardHeader>
                        <div className='cardtxtdao'>
                        
                        <Text className='cardtxtdao'>
                           {callsign}
                        </Text></div>
                        

                        <br></br>
                        
                        <div className='cardtxtdao'>
                        
                        <Text className='cardtxtdao'>
                         {balance}
                        </Text></div>
                        <br></br>

                        <div className='cardtxtdao'>
                        
                        <Text className='cardtxtdao'>
                         {nftbalance}
                        </Text></div>
                        <br></br>
                       
                        <div className='cardtxtdao'>
                        
                        <Text className='cardtxtdao'>
                       {bravoPoints}
                        </Text></div>
                        <br></br>
                        <div className='cardtxtdao'>
                         
                        
                        <Text className='cardtxtdao' >
                        {alphaPoints}
                        </Text></div>
                        <br></br>
                        <div className='cardtxtdao'>
                        
                        <Text className='cardtxtdao' >
                          {shillPoints}
                        </Text></div>
                      
                        
            
                        
                     <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => initalizeUser()}>
                        <font><b>Initialize Wallet</b></font>
                        </Button>
                        <br></br>

                    </Card>














                   

                </Grid>





              <br></br>
              <br></br>





                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="30px">
                   









                    <Card className='DaoHeader' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text className='heading'><b>
                            Proposal #1
                            </b></Text>
                        </CardHeader>
                        <div className='cardtxtdao'>
                        
                        <Text  className='cardtxtdao' fontSize={16}>
                           Total Votes On This Proposal
                        </Text></div>
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={15}>
                           {votes1}
                        </Text></div>
                        <br></br>
                        <div className='cardtxt'>
                        
                        <Text className='heading'>
                           Title
                        </Text></div>
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={16}>
                           {proposal1}
                        </Text></div>
                        

  
                       
                        <br></br>

                        <div className='heading'>
                        <Text >
                        Message:
                        </Text></div>
                       
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={16}>
                           {message1}
                        </Text></div>
                       
                        <br></br>

                        

                     
                        <br></br>
                        <div className='cardtxtdao'>
                        <Text className='heading'><b>
                            Status
                            </b></Text>
                        <Text className='cardtxtdao' color="whiteAlpha.800" fontSize={14}>
                          {result1}
                        </Text>
                        <br></br>
                        
                        <Text color="whiteAlpha.800" fontSize='14px'>
                        Voting Opens:<br></br>
                        {start1 !== 0
                          ? new Date(start1 * 1000).toLocaleString([], {
                              month:'numeric',
                              day:  'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })
                          : 'Not Live'}
                        </Text>
                        <br></br>
                        <Text color="whiteAlpha.800" fontSize='14px'>
                        Voting Closes:<br></br>
                        {end1 !== 0
                          ? new Date(end1 * 1000).toLocaleString([], {
                              month:'numeric',
                              day:  'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })
                          : 'Not Live'}
                        </Text>
                        <br></br>
                        <input type="text" className="form-control" onChange={handleDepositChangepusd1} value={depositValuepusd1} placeholder="Number Of Votes"></input> </div>
                     <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => setExc1Tweet(depositValuepusd1)} >
                        <font><b>Vote For</b></font>
                        </Button>
                        <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => setDeny1(depositValuepusd1)} >
                        <font><b>Vote Against</b></font>
                        </Button>
                        <br></br>
                        
                     
                        <div>
                        
                        </div>

                        

                    </Card>














                    <Card className='DaoHeader' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text className='heading'><b>
                            Proposal #2
                            </b></Text>
                        </CardHeader>
                        <div className='cardtxtdao'>
                        
                        <Text  className='cardtxtdao' fontSize={16}>
                           Total Votes On This Proposal
                        </Text></div>
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={15}>
                           {votes2}
                        </Text></div>
                        <br></br>
                        <div className='cardtxt'>
                        
                        <Text className='heading'>
                           Title
                        </Text></div>
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={16}>
                           {proposal2}
                        </Text></div>
                        

  
                       
                        <br></br>

                        <div className='heading'>
                        <Text >
                        Message:
                        </Text></div>
                       
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={16}>
                           {message2}
                        </Text></div>
                       
                        <br></br>

                        

                     
                        <br></br>
                        <div className='cardtxtdao'>
                        <Text className='heading'><b>
                            Status
                            </b></Text>
                        <Text className='cardtxtdao' color="whiteAlpha.800" fontSize={14}>
                          {result2}
                        </Text>
                        <br></br>
                        
                        <Text color="whiteAlpha.800" fontSize='14px'>
                        Voting Opens:<br></br>
                        {start2 !== 0
                          ? new Date(start2 * 1000).toLocaleString([], {
                              month:'numeric',
                              day:  'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })
                          : 'Not Live'}
                        </Text>
                        <br></br>
                        <Text color="whiteAlpha.800" fontSize='14px'>
                        Voting Closes:<br></br>
                        {end2 !== 0
                          ? new Date(end2 * 1000).toLocaleString([], {
                              month:'numeric',
                              day:  'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })
                          : 'Not Live'}
                        </Text>
                        <br></br>
                        <input type="text" className="form-control" onChange={handleDepositChangepusd2} value={depositValuepusd2} placeholder="Number Of Votes"></input> </div>
                     <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => setExc2Tweet(depositValuepusd2)} >
                        <font><b>Vote For</b></font>
                        </Button>
                        <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => setDeny2(depositValuepusd2)} >
                        <font><b>Vote Against</b></font>
                        </Button>
                        <br></br>
                        
                     
                        <div>
                        
                        </div>

                        

                    </Card>




                    <Card className='DaoHeader' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text className='heading'><b>
                            Proposal #3
                            </b></Text>
                        </CardHeader>
                        <div className='cardtxtdao'>
                        
                        <Text  className='cardtxtdao' fontSize={16}>
                           Total Votes On This Proposal
                        </Text></div>
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={15}>
                           {votes3}
                        </Text></div>
                        <br></br>
                        <div className='cardtxt'>
                        
                        <Text className='heading'>
                           Title
                        </Text></div>
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={16}>
                           {proposal3}
                        </Text></div>
                        

  
                       
                        <br></br>

                        <div className='heading'>
                        <Text >
                        Message:
                        </Text></div>
                       
                        <div className='cardtxtdao'>
                        <Text color="whiteAlpha.800" fontSize={16}>
                           {message3}
                        </Text></div>
                       
                        <br></br>

                        

                     
                        <br></br>
                        <div className='cardtxtdao'>
                        <Text className='heading'><b>
                            Status
                            </b></Text>
                        <Text className='cardtxtdao' color="whiteAlpha.800" fontSize={14}>
                          {result3}
                        </Text>
                        <br></br>
                        
                        <Text color="whiteAlpha.800" fontSize='14px'>
                        Voting Opens:<br></br>
                        {start3 !== 0
                          ? new Date(start3 * 1000).toLocaleString([], {
                              month:'numeric',
                              day:  'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })
                          : 'Not Live'}
                        </Text>
                        <br></br>
                        <Text color="whiteAlpha.800" fontSize='14px'>
                        Voting Closes:<br></br>
                        {end3 !== 0
                          ? new Date(end3 * 1000).toLocaleString([], {
                              month:'numeric',
                              day:  'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })
                          : 'Not Live'}
                        </Text>
                        <br></br>
                        <input type="text" className="form-control" onChange={handleDepositChangepusd3} value={depositValuepusd3} placeholder="Number Of Votes"></input> </div>
                     <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => setExc3Tweet(depositValuepusd3)} >
                        <font><b>Vote For</b></font>
                        </Button>
                        <br></br>
                        <Button className='btnsubmit' border='1px' borderRadius={30} variant='transparent' marginInline={2} fontSize="15px" colorScheme='blue' color='#E9D8FD' onClick={() => setDeny3(depositValuepusd3)} >
                        <font><b>Vote Against</b></font>
                        </Button>
                        <br></br>
                        
                     
                        <div>
                        
                        </div>

                        

                    </Card>


                      


                </Grid>
                <br></br>
                <br></br>
                <Card  className='DaoHeader' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="20px" fontWeight="bold">
                        <CardHeader className='cardtxtdao'>
                        <Text className='heading'><b>
                            How It Works
                            </b></Text>
                        </CardHeader>




                        <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="15px">


                            <Card className='DaoHeaderfaq' textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        
                        <CardHeader className='cardtxtdao'>
                        <Text className='heading'><b>
                            How To Initialize?
                            </b></Text>
                        </CardHeader>
                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                            Initialization is only a one-time transaction with your wallet holding AllIn, the user must Initialize to be able to receive votes. Simply click "Initialize Wallet" to register your wallet for voting. </Text>
                        </CardBody>
                        </Card>

                        <Card className='DaoHeaderfaq' textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxtdao'>
                        <Text className='heading'><b>
                            How To Vote?
                            </b></Text>
                        </CardHeader>
                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                            The process is very simple, After receiving your votes which are displayed on the top of the page under "Proposal 1, 2, 3 Votes" Review the proposal in the corresponding category and enter your vote amount then click "Vote For" Or "Vote Against".</Text>
                        </CardBody>
                        </Card>


                        <Card className='DaoHeaderfaq' textAlign="center"  borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxtdao'>
                        <Text className='heading'><b>
                            When Do I Get My Votes?
                            </b></Text>
                        </CardHeader>
                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                            Votes are received when voting is about to begin. A call will be made to the contract by the AllIn team to distribute votes based on AllIn & ALLBOTs holdings.
                            </Text>
                        </CardBody>
                        </Card>

                       








                     </Grid>
                   
                      

                    </Card>
















                


                <Box textAlign="center" mt="25px" >
                    
                </Box>
                
            </Box>

            <Box>
                <Text textAlign="center" fontSize="30px" color="white" fontWeight="bold" my="35px"></Text>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)'}} gap="60px">

                        
                      

                </Grid>
            </Box>
        </Box>
    );
};

export default Dao;