import { Flex } from '@chakra-ui/react';

import React, { Component } from "react";



const Loader = () => {

 


  





  return (
    

    
    <div class="wrapper">
    <svg className='svg1'>
      <text x="50%" y="50%" dy=".35em" text-anchor="middle">
        Profit Pass
      </text>
    </svg>
  </div>
  
  );
}

export default Loader;