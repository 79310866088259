import { Box, Button, Card, CardBody, CardFooter, CardHeader, Flex, Grid, Heading, HStack, Image, Input, Link, Progress, Stack, Text, useNumberInput } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import img from '../Images/maxxgif.gif';


import { useAccount } from 'wagmi';

import { ethers } from 'ethers';
import AlphaLions from '../artifacts/contracts/LionNFT.sol/LionNFT.json';
import AlphaToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import Swal from 'sweetalert2';
const Toast = Swal.mixin({
  toast: false,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const ALaddress = '0xba32beEECfcE75aDAdF293c64B15EDf8C2D27d04';





const Mint = () => {

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
        step: 1,
        defaultValue: 1,
        min: 1,
        max: 5,
        precision: 0,
    })
  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()
  const value = input.value;
//   console.log(value);

  const { address, isConnecting, isDisconnected} = useAccount();

  const [data, setData] = useState({});
  const [allow, setAllow] = useState();
  const [owner, setOwner] = useState(false);
  const [price, setPrice] = useState();

  useEffect(() => {
    getContractOwner();
    fetchData();
  }, [address])



  async function getContractOwner() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, provider);
      try {
       if(address) {
          const owner = await contract.owner();
          if(owner.toLowerCase() === address.toLowerCase()) {
            setOwner(true);
          } else {
            setOwner(false);
          }
       }
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }

  async function fetchData() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, provider);

      try {
       

        const mintPrice = await contract.cost();
        let tokenPrice = mintPrice / 1000000000000000000;
        const price = parseFloat(tokenPrice).toFixed(2);
   

        const totalSupply = await contract.totalSupply();
        const object = {"price": String(tokenPrice), "totalSupply": String(totalSupply)};
        setData(object);
        setPrice(price)
        console.log(object)
        setTimeout(fetchData,15000);
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }
  



  async function mint() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);
      const totalValueInWei = ((data.price * value) * 10**18).toString()
      console.log(totalValueInWei)
      try {
        let overrides = {
          from: address,
          value: totalValueInWei
        }
        const transaction = await contract.mint(value, overrides);
        const swalOptions = {
          icon: 'info',
          title: 'Transaction Pending',
          text: 'Please wait while the transaction is being processed...',
          allowOutsideClick: true,
          showCancelButton: false,
          showConfirmButton: true,
          timer: 5000, // Set timer for 5 seconds (5000 milliseconds)
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        };
    
        await Swal.fire(swalOptions);
    
 
        await transaction.wait();
        
        console.log('successful');
    
        const successSwalOptions = {
          icon: 'success',
          title: 'Transaction Successful',
          text: 'Mint Successful!',
        
          allowOutsideClick: true,
          showCancelButton: false,
          showConfirmButton: true
        };
        fetchData();
    
        const successSwalResult = await Swal.fire(successSwalOptions);
        const successSwalContainer = successSwalResult.isConfirmed ? successSwalResult.container : null;
    
        const transactionHashButton = document.getElementById('transactionHashButton');
       
      }

      catch (error) {
        console.error('Transaction failed:', error);
 
  
       
       
    
        Swal.fire({
          icon: 'error',
          title: 'Transaction Failed',
          text: error.message,
          allowOutsideClick: true
        });
      }
    }
  }



  












  async function withdraw() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(ALaddress, AlphaLions.abi, signer);
      try {
        const transaction = await contract.withdrawETH();
        await transaction.wait();
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }


    return (
        <Box  mb="100px">

            <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="60px">
                <Stack order={{base: '2', md:'1'}} spacing="25px" >
                <Text color="#8fc5ff" fontSize="3xl" fontWeight="bold">
                        MaxxChain 
                    </Text>
                    <Text color="#8fc5ff" fontSize="3xl" fontWeight="bold">
                    Profit Pass 
                    </Text>
                    <Text className='nftMint'>
                        MINTING LIVE
                      </Text>
                      <br></br>
                    

          
      
           
                    <Flex justify="space-between" align="center">
                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            TOTAL NFT's: <span style={{fontWeight: 'bold', color: 'white'}}>300</span>
                        </Text>
                        <br></br>

                        <Text className='nftMint' fontSize="18px" color="primary.600">
                            PRICE: <span style={{fontWeight: 'bold', color: 'white'}}>{price} ETH</span>
                        </Text>

                    </Flex>

                    <Flex justify="space-between" align="center" wrap='wrap' gap="15px">
                    <Text className='nftMint'>
                        Network: <span>Ethereum</span>
                    </Text>


                    </Flex>

                    <Text className='nftMint1'>
                    UNLOCK A NEW REALM OF PASSIVE INCOME FROM ALL PWRSOURCE BRIDGE TRANSACTIONS (POWERED BY BLOCKBLEND) WITH THE EXCLUSIVE PROFIT PASS COLLECTION. 
                    </Text>
                    <Text className='nftMint1'>
                    ALL REVENUE GENERATED FROM PWRSOURCE BRIDGE RELAYER FEES WILL BE AWARDED TO PROFIT PASS HOLDERS (AWARDED IN USDT BEP20).
                      </Text>
                    

                    <Link href='https://opensea.io/collection/maxxchain-profit-pass' _hover={{textDecoration: 'none'}} target="_blank">
                        <Button border="1px"  w="100%">
                            View on Opensea
                        </Button>
                    </Link>
                   
                    

                </Stack>

                <Stack order={{base: '1', md:'1'}} spacing="25px">
                    <Flex justify="center">
                      <Image src={img} alt="nftImage"borderRadius="30px" w={{base: 'auto', lg: "300px"}} h="400px" objectFit="contain"/>
                    </Flex>

                    <Box>
                        <Flex justify="space-between" fontSize="17px">
                       
                            <span className='nftMint'>Total minted</span>
                            <Text className='nftMint'><span style={{color: 'white', fontWeight: 'bold'}}>{((data.totalSupply/300)*100).toFixed(0)}%</span> ({data.totalSupply}/300)</Text>
                        </Flex>
                        <Box mt="5px">
                            <Progress bg="secondary.800" size="md" value={((data.totalSupply/200)*100).toFixed(0)} colorScheme="green" border="2px" borderRadius="full"/>
                        </Box>
                    </Box>

                    <Card bg="secondary.700" color="white">
                        <CardHeader fontSize="18px" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="15px">
                      
                 
                        <Text className='nftMint' >
                            Price: <span style={{fontWeight: 'bold'}}>{price} ETH</span>
                        </Text>
                        <HStack maxW='320px'>
                            <Button {...dec} colorScheme="cyan">-</Button>
                            <Input className='counter1' {...input}/>
                            <Button {...inc} colorScheme="cyan">+</Button>
                        </HStack>
                        </CardHeader>


                        <CardBody>
                     
                            <Button className='buttontxt' border="1px" w="100%" colorScheme="white" onClick={mint} disabled={isDisconnected && true}>Mint {value}</Button>
                        </CardBody>
                    </Card>
                </Stack>
            </Grid>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        
     

        </Box>
    );
};

export default Mint;