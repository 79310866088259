import React, {useState, useEffect} from 'react';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

import LionStaking from '../artifacts/contracts/LionStaking.sol/LionStaking.json';
import LionToken from '../artifacts/contracts/LionToken.sol/LionToken.json';
import Logo from '../Images/logo.png';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import UnstakedNFT from 'Components/UnstakedNFT';
import '../Styles/Home.css'
import Pdf from '../PDF/Guide.pdf'

import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})




const tokenContractAddress = '0x9b2b931d6ab97b6a887b2c5d8529537e6fe73ebe';
const stakingContractAddress = '0x6aE30c8b30dE30E6207076264931F543821BB520';

const Staking = () => {

    const { address, isConnecting, isDisconnected} = useAccount();
    const [ownerTokenStakedId, setOwnerTokenStakedId] = useState([]);
    const [rewardsAmount, setRewardsAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [PoolData, setfetchpoolData] = useState(0);
    const [warchestTotal, setWarchestCount] = useState([])
    const [rumTotal, setRumCount] = useState([])


    useEffect(() => {
  
      fetchTokenData();
      updateData();
      fetchrumData();
      fetchwarchestData();
    }, [address])

    const updateData = () => {
      if(isDisconnected) {
      setOwnerTokenStakedId([]);
      setRewardsAmount(0);
      setBalance(0);
      setfetchpoolData(0);
      }
    }
   


  async function fetchwarchestData() {
    if(typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
      try {
          if(address) {
            const warchest = await contract.warchestCount(address);
            let warchestCount = await(warchest);
            let a = warchestCount;
            let warchestTotal = a.toString()
              
              setWarchestCount(warchestTotal)
              console.log(warchestTotal) 
              setTimeout(fetchwarchestData, 15000 );
          } }
          catch (err) {
            console.log(err.message);
          }
        }
    }


    async function fetchrumData() {
      if(typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(stakingContractAddress, LionStaking.abi, provider);
        try {
          if(address) {
            const rum = await contract.rumCount(address);
            let rumCount = await(rum);
            let rumTotal = rumCount.toString()
              
              setRumCount(rumTotal)
              console.log(rumTotal) 
              setTimeout(fetchrumData, 15000 );
          }}
          catch (err) {
            console.log(err.message);
          }
        }
    }


   
  
    
   
  
  
   
    
  

    async function fetchTokenData() {
        if(typeof window.ethereum !== 'undefined') {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(tokenContractAddress, LionToken.abi, provider);
          try {
            if(address) {
              const accountBalance = await contract.balanceOf(address);
              
              setBalance((parseInt(accountBalance._hex) / 10 ** 18).toFixed(2));
            }
            setTimeout(fetchTokenData, 15000 );
          }
          catch (err) {
            console.log(err.message);
          }
        }
    }

    
    

  

    return (
        <Box mb="100px">
            <Box >
              <center>
                <Image
                    src={Logo}
                    alt='MemeAI'
                    transform="scale(1)"
                    width='150px'
                    transition= "0.3s ease-in-out"
                    _hover={{transform: "scale(1.15)"}}
                    />
                    <Heading className='headertxt'  bgGradient='linear(to-r, #4DFFFF, #4D82FF)' bgClip='text' size={{base: 'md', md: "xl"}}>
                      ALLBOTS TERMINAL
                    </Heading>
                    <br>
                    </br>
                    
                    <a className="btnsubmit" href={Pdf} target="_blank" rel="noopener noreferrer">User Guide</a>
                    
                </center>
                <br></br>

                


                <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)'}} gap="60px">
                   

                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text>
                            Allin Balance
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text color="whiteAlpha.800">
                                {balance} 
                            </Text>
                        </CardBody>
                    </Card>


                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text>
                            RAM Balance
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text color="whiteAlpha.800">
                                {rumTotal} 
                            </Text>
                        </CardBody>
                    </Card>

                    <Card textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt'>
                        <Text>
                        Allinium Balance
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text color="whiteAlpha.800">
                                {warchestTotal} 
                            </Text>
                        </CardBody>
                    </Card>


                </Grid>

                <Box textAlign="center" mt="25px" >
                    
                </Box>
                
            </Box>

<br>
</br>
            <Box>
                <Text textAlign="center" fontSize="30px" color="white" fontWeight="bold" my="35px"></Text>

                <Grid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)'}} gap="60px">

                        
                        <UnstakedNFT/>
                     
                        

                </Grid>
            </Box>
        </Box>
    );
};

export default Staking;