import React, {useState, useEffect} from 'react';

import { ethers } from 'ethers';
import { useAccount } from 'wagmi';


import Logo from '../Images/logo.png';
import Level1 from '../Images/level1.jpeg';
import Level2 from '../Images/level2.jpeg';
import { Box, Heading, Button, Card, CardBody, Image, CardHeader, Flex, Grid, Text } from '@chakra-ui/react';
import Pdf from '../PDF/Guide.pdf'

import '../Styles/Home.css'

import Swal from 'sweetalert2'

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  
 
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})






const Staking = () => {

  

    return (
        <Box mb="100px">
            <Box >
              <center>
                <Image
                    src={Logo}
                    alt='MemeAI'
                    transform="scale(1)"
                    width='150px'
                    transition= "0.3s ease-in-out"
                    _hover={{transform: "scale(1.15)"}}
                    />
                    <Heading className='headertxt'  bgGradient='linear(to-r, #4DFFFF, #4D82FF)' bgClip='text' size={{base: 'md', md: "xl"}}>
                      ALLBOTS FAQ
                    </Heading>
                    <br>
                    </br>
                    
                    <a className='btnsubmit' href = {Pdf} target = "_blank">User Guide</a>
                </center>
                <br></br>

                


                <Grid className='faqgrid1' templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="60px">
                   

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt1'>
                        <Text>
                            What Does RAM Do?
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                               RAM is a part of a stratigic leveling add-on built within the ALLBOT smart contract. RAM is aquired by paying ETH & can be consumed by an ALLBOT of your choosing giving it 1 additional level.
                            </Text>
                        </CardBody>
                    </Card>


                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt1'>
                        <Text>
                            What Does ALLINIUM Do?
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                            ALLINIUM is a part of a stratigic leveling add-on built within the ALLBOT smart contract.  ALLINIUM is aquired by paying ETH & can be consumed by an ALLBOT of your choosing giving it 10 additional levels.
                           
                            </Text>
                        </CardBody>
                    </Card>

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt1'>
                        <Text>
                        How Does The XP System Work?
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                                We have modeled a sigmoidal function in solidity providing ALLBOTS with a unique non-linear leveling system, what does this mean you might ask. Our leveling system is dependant on time meaning the longer you own the ALLBOT the higher the level it will become. You can choose to upgrade your ALLBOT with RAM & ALLINIUM, allowing you to own the most powerful ALLBOT in existence.
                            </Text>
                        </CardBody>
                    </Card>

                    <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                        <CardHeader className='cardtxt1'>
                        <Text>
                        How Does The Level System Work?
                        </Text>
                        </CardHeader>

                        <CardBody>
                            <Text className='leadertxt' color="whiteAlpha.800">
                                Our Level system is built for 100 levels, as your XP grows per day your level increases. Claiming up your XP is simple, under your ALLBOT terminal click CLAIM XP and view your ALLBOT level in the stats page.
                            </Text>
                        </CardBody>
                    </Card>

<br></br>
<br></br>
                </Grid>
                


                <Grid className='faqgrid2' templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="60px">
                   

                   <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                       <CardHeader className='cardtxt1'>
                       <Text>
                           Cheat Sheet Of Our Level System. 

                       </Text>
                       <Text>
                           Level 1-50
                       </Text>
                       </CardHeader>

                       <CardBody>
                       <img
                      className='nft-image'
                      src={`${Level1}`}
                      alt='nft-image'
                    />
                       </CardBody>
                   </Card>
                   <Card className='leadcard' textAlign="center" border="2px" borderColor='blue.50' borderRadius="30px"  fontSize="25px" fontWeight="bold">
                       <CardHeader className='cardtxt1'>
                       <Text>
                           Cheat Sheet Of Our Level System. 

                       </Text>
                       <Text>
                           Level 51-100
                       </Text>
                       </CardHeader>

                       <CardBody>
                       <img
                      className='nft-image'
                      src={`${Level2}`}
                      alt='nft-image'
                    />
                       </CardBody>
                   </Card>




               </Grid>

                <Box textAlign="center" mt="25px" >
                    
                </Box>
                
            </Box>

<br>
</br>
           
        </Box>
    );
};

export default Staking;